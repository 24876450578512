const Prices = () => {
  return (
    <div className="min-h-screen p-8 bg-transparent">
      <h1 className="text-3xl font-bold mb-8 text-center">Hinnasto</h1>
      <div className="space-y-8 max-w-4xl mx-auto">
        <section className="p-4 bg-white shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-4">1. Jäsenmaksu</h2>
          <p className="text-lg">
            Jäsenmaksu on 39,90€/vuosi (sis. alv.) (sisältää 40 krediittiä)
          </p>
        </section>
        <section className="p-4 bg-white shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-4">2. Vaihtomaksu</h2>
          <ul className="list-disc list-inside space-y-2 text-lg">
            <li>
              Lomaviikon varauksesta peritään 129,00€ (sis. alv) vaihtomaksu +
              kyseisen lomaviikon krediitit. (näkyvät ilmoituksessa)
            </li>
            <li>
              Äkkilähdöt (4 viikkoa ennen loman alkua) = puolet krediittien
              määrästä, päivittyvät automaattisesti ilmoitukselle.
            </li>
            <li>
              Äkkilähtöviikkoja voi myös varata rajoituksetta menettämättä omia
              krediittejä maksamalla kiinteän viikkohinnan 149,00€ (sis. alv).
            </li>
          </ul>
        </section>

        <section className="p-4 bg-white shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-4">
            3. Lisäkrediittien ostaminen
          </h2>
          <ul className="list-disc list-inside space-y-2 text-lg">
            <li>
              Mikäli lompakossa olevat krediitit eivät riitä valittuun
              lomaviikkoon, lisäkrediittejä voi ostaa varauksen yhteydessä.
            </li>
            <li>1€/krediitti (sis. alv)</li>
          </ul>
          <p className="text-lg mt-4">
            Esimerkki: <br />
            Jos varaat lomaviikon, joka maksaa 100 krediittiä, mutta sinulla on
            vain 80 krediittiä lompakossasi, voit ostaa 20 lisäkrediittiä
            hintaan 20€.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Prices;
