import Calculator from "../components/creditCalculator/Calculator";

const CalculatorPage = () => {
  return (
    <div className="min-h-screen p-8 bg-transparent">
      <div className="space-y-8 max-w-4xl mx-auto">
        <Calculator />
      </div>
    </div>
  );
};

export default CalculatorPage;
