import { useEffect, useState } from "react";
import Card from "../components/Card";
import axios from "axios";
import { useAuth } from "../context/authContext";
import ConfirmationModal from "../components/ConfirmationModal";

const Home = () => {
  const [timeShares, setTimeShares] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTimeshare, setSelectedTimeshare] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;
  const { user, updateUserCredits } = useAuth();

  useEffect(() => {
    const fetchTimeShares = async () => {
      try {
        const response = await fetch(`${apiUrl}/timeshares/listed`, {
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (Array.isArray(data)) {
          setTimeShares(data);
        } else {
          setTimeShares([]);
        }
        updateUserCredits();
      } catch (error) {
        console.error("Error fetching timeShares:", error);
      }
    };

    fetchTimeShares();
  }, [apiUrl, updateUserCredits]);

  const onBookingClick = (timeshare) => {
    if (user.credits >= timeshare.credits) {
      axios
        .put(
          `${apiUrl}/timeshares/book/${timeshare.unit_id}`,
          {
            credits: timeshare.credits,
            user_id: user.id,
          },
          {
            withCredentials: true,
          }
        )
        .then(() => {
          updateUserCredits();
          setTimeShares((prevTimeShares) =>
            prevTimeShares.filter((ts) => ts.unit_id !== timeshare.unit_id)
          );
          setShowModal(false);
        })
        .catch((error) => {
          console.error("Error booking timeshare:", error);
          alert("Error booking timeshare.");
        });
    } else {
      alert(
        "Sinulla ei ole tarpeeksi lomakrediittejä tämän viikon varaamiseen."
      );
    }
  };

  const handleBookingClick = (timeshare) => {
    setSelectedTimeshare(timeshare);
    setShowModal(true);
  };

  const checkOffer = (timeshare) => {
    const currentDate = new Date();
    const timeshareStartDate = new Date(timeshare.start_date);
    const thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(currentDate.getDate() + 30);

    return timeshareStartDate <= thirtyDaysFromNow;
  };

  return (
    <div className="min-h-screen bg-transparent flex flex-col justify-start p-4">
      {user && (
        <div className="flex justify-center">
          <div className="bg-white bg-opacity-80 rounded-xl p-4 m-4">
            <h1 className="text-3xl font-bold mx-4 text-center">
              Varaa heti, kaikki lomat 129€/vk
            </h1>
          </div>
        </div>
      )}
      <div className="flex items-center justify-center">
        {user ? (
          timeShares.length === 0 ? (
            <p>No listed timeshares available.</p>
          ) : (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {timeShares.map((timeShare, index) => (
                  <Card
                    key={index}
                    timeshare={timeShare}
                    buttonText="Varaa nyt"
                    onClick={() => handleBookingClick(timeShare)}
                    offer={checkOffer(timeShare)}
                  />
                ))}
              </div>
              {showModal && selectedTimeshare && (
                <ConfirmationModal
                  prompt={`Haluatko varmasti varata tämän viikon ${selectedTimeshare.credits} lomakrediitillä?`}
                  onConfirm={() => onBookingClick(selectedTimeshare)}
                  onCancel={() => setShowModal(false)}
                />
              )}
            </>
          )
        ) : (
          <div className="bg-white rounded-xl bg-opacity-50">
            <p className="text-xl m-4">
              Kirjaudu sisään ja talleta viikko, niin pääset selaamaan kohteita
              ja varaamaan vapaita viikkoja.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
