import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const login = useCallback((userData) => {
    setUser(userData);
  }, []);

  const logout = useCallback(async () => {
    try {
      await axios.post(`${apiUrl}/auth/logout`, {}, { withCredentials: true });
    } catch (error) {
      console.error("Error during logout:", error);
    }
    setUser(null);
  }, [apiUrl]);

  const updateUserCredits = useCallback(async () => {
    try {
      const response = await axios.get(`${apiUrl}/auth/user`, {
        withCredentials: true,
      });
      if (response.data.isAuthenticated) {
        setUser(response.data.user);
      }
    } catch (error) {
      console.error("Error updating user credits:", error);
    }
  }, [apiUrl]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/auth/user`, {
          withCredentials: true,
        });
        if (response.data.isAuthenticated) {
          login(response.data.user);
        } else {
          console.log("user is not authenticated on initial load");
          setUser(null); // Explicitly set user to null
          // Optional: Redirect to login page
        }
      } catch (error) {
        console.error("Error fetching user:", error);
        setUser(null); // Clear user state if there's an error
      }
    };

    fetchUser();
  }, [apiUrl]);

  return (
    <AuthContext.Provider value={{ user, login, logout, updateUserCredits }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
