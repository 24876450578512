import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import whitelogo from "../../assets/logo_valk.png";
import greenlogo from "../../assets/logo_väri.png";
import hamburger from "../../assets/hamburger.png";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false); // Track if the user has scrolled

  const { user, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled down at least 50px
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/auth/logout`, {
        withCredentials: true,
      });
      logout();
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div
      className={`fixed top-0 w-full h-24 sm:h-24 md:h-32 lg:h-36 xl:h-36 2xl:h-36 3xl:h-36 flex items-center z-50 transition duration-300 ${
        isScrolled ? "bg-teal-600" : "bg-transparent"
      }`}
    >
      <div className="flex w-full justify-between items-center px-24">
        <img
          src={whitelogo}
          alt="logo"
          className="h-18 w-80 hover:cursor-pointer"
          onClick={() => navigate("/")}
        />

        {/* Menu Toggle Button for Mobile */}
        <button
          className={`md:hidden absolute top-4 right-4 focus:outline-none ${
            isScrolled ? "text-white" : "text-black"
          }`}
          onClick={toggleMenu}
        >
          <img src={hamburger} alt="menu" className="h-8 w-8" />
        </button>

        <div
          className={`${
            isMenuOpen ? "block" : "hidden"
          } absolute top-24 left-0 w-full bg-white md:static md:flex md:flex-row md:w-auto md:bg-transparent`}
        >
          {/* Navigation Links */}
          {[
            { href: "/", label: "Etusivu" },
            { href: "/hinnat", label: "Hinnasto" },
            { href: "/laskin", label: "Lomakrediittilaskuri" },
            { href: "/kayttoehdot", label: "Käyttöehdot" },
          ].map((link) => (
            <div
              className="flex items-center rounded-2xl h-12 my-4"
              key={link.href}
            >
              <a
                href={link.href}
                className={`m-4 transition duration-200 px-4 py-2 rounded-xl ${
                  isScrolled
                    ? "text-white hover:underline"
                    : "text-black bg-white bg-opacity-80 hover:underline"
                } ${
                  !isScrolled
                    ? "hover:scale-105 transform hover:bg-transparent"
                    : ""
                } hover:bg-transparent`}
              >
                {link.label}
              </a>
            </div>
          ))}

          {user ? (
            <div className="flex items-center">
              {/* User-specific links */}
              <div className="flex items-center rounded-2xl h-12 my-4">
                <a
                  href="/profiili"
                  className={`m-4 transition duration-200 px-4 py-2 rounded-xl ${
                    isScrolled
                      ? "text-white hover:underline"
                      : "text-black bg-white bg-opacity-80 hover:underline"
                  } ${
                    !isScrolled
                      ? "hover:scale-105 transform hover:bg-tansparent"
                      : ""
                  } hover:bg-transparent`}
                >
                  Oma sivu
                </a>
              </div>

              <div className="flex items-center rounded-2xl h-12 my-4">
                <p
                  className={`m-4 transition duration-200 px-4 py-2 rounded-xl ${
                    isScrolled
                      ? "text-white bg-transparent"
                      : "text-black bg-white bg-opacity-80"
                  }`}
                >
                  Lomakrediittisaldo{user ? `: ${user.credits}` : ""}
                </p>
              </div>

              {/* Admin-specific links */}
              {user.isAdmin && (
                <>
                  <div className="flex items-center rounded-2xl h-12 my-4">
                    <a
                      href="/kayttajat"
                      className={`m-4 transition duration-200 px-4 py-2 rounded-xl ${
                        isScrolled
                          ? "text-white hover:underline"
                          : "text-black bg-white bg-opacity-80 hover:underline"
                      } ${
                        !isScrolled ? "hover:scale-105 transform" : ""
                      } hover:bg-transparent`}
                    >
                      Käyttäjät
                    </a>
                  </div>

                  <div className="flex items-center rounded-2xl h-12 my-4">
                    <a
                      href="/kuvat"
                      className={`m-4 transition duration-200 px-4 py-2 rounded-xl ${
                        isScrolled
                          ? "text-white hover:underline"
                          : "text-black bg-white bg-opacity-80 hover:underline"
                      } ${
                        !isScrolled ? "hover:scale-105 transform" : ""
                      } hover:bg-transparent`}
                    >
                      Kuvat
                    </a>
                  </div>

                  <div className="flex items-center rounded-2xl h-12 my-4">
                    <a
                      href="/linkit"
                      className={`m-4 transition duration-200 px-4 py-2 rounded-xl ${
                        isScrolled
                          ? "text-white hover:underline"
                          : "text-black bg-white bg-opacity-80 hover:underline"
                      } ${
                        !isScrolled ? "hover:scale-105 transform" : ""
                      } hover:bg-transparent`}
                    >
                      Linkit
                    </a>
                  </div>
                </>
              )}

              <button
                className={`m-4 px-4 py-2 rounded-xl transition duration-200 ${
                  isScrolled
                    ? "bg-teal-600 text-white hover:underline"
                    : "bg-white bg-opacity-80 text-black hover:bg-transparent hover:underline"
                }`}
                onClick={handleLogout}
              >
                Kirjaudu ulos
              </button>
            </div>
          ) : (
            <button
              className={`m-4 px-4 py-2 rounded-xl transition duration-200 ${
                isScrolled
                  ? "bg-teal-600 text-white hover:underline"
                  : "bg-white bg-opacity-80 text-black hover:bg-transparent hover:underline"
              }`}
              onClick={() => navigate("/kirjaudu")}
            >
              Kirjaudu sisään
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
