import logo from "../../assets/logo_valk.png";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Footer = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleLogout = async () => {
    try {
      await axios.get(`${apiUrl}/auth/logout`, {
        withCredentials: true,
      });
      logout();
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="bg-teal-700 w-full h-64 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
          <img src={logo} alt="logo" className="h-18 w-80 my-2" />
          <div className="flex flex-row">
            <div className="flex items-center rounded-2xl h-12 my-4">
              <a
                href="/"
                className="m-4 text-white px-4 py-2 rounded-2xl hover:bg-teal-700 transition duration-200 hover:underline"
              >
                Etusivu
              </a>
            </div>

            <div className="flex items-center rounded-2xl h-12 my-4">
              <a
                href="/prices"
                className="m-4 text-white px-4 py-2 rounded-2xl hover:bg-teal-700 transition duration-200 hover:underline"
              >
                Hinnasto
              </a>
            </div>

            <div className="flex items-center rounded-2xl h-12 my-4">
              <a
                href="/calculator"
                className="m-4 text-white px-4 py-2 rounded-2xl hover:bg-teal-700 transition duration-200 hover:underline"
              >
                Lomakrediittilaskuri
              </a>
            </div>

            <div className="flex items-center rounded-2xl h-12 my-4">
              <a
                href="/terms"
                className="m-4 text-white px-4 py-2 rounded-2xl hover:bg-teal-700 transition duration-200 hover:underline"
              >
                Käyttöehdot
              </a>
            </div>

            {user ? (
              <div className="flex items-center">
                <div className="flex items-center rounded-2xl h-12 my-4">
                  <a
                    href="/profile"
                    className="m-4 text-white px-4 py-2 rounded-2xl hover:bg-teal-700 transition duration-200 hover:underline"
                  >
                    Oma sivu
                  </a>
                </div>

                <div className="flex items-center rounded-2xl h-12 my-4">
                  <p className="m-4 text-white px-4 py-2 rounded-2xl hover:bg-teal-700 transition duration-200 hover:underline">
                    Lomakrediittisaldo{user ? `: ${user.credits}` : ""}
                  </p>
                </div>

                <button
                  className="m-4 text-white px-4 py-2 rounded-2xl hover:bg-teal-700 hover:underline transition duration-200"
                  onClick={handleLogout}
                >
                  Kirjaudu ulos
                </button>
              </div>
            ) : (
              <button
                className="m-4 text-white px-4 py-2 rounded-2xl hover:bg-teal-700 hover:underline transition duration-200"
                onClick={() => navigate("/login")}
              >
                Kirjaudu sisään
              </button>
            )}
          </div>
          <hr className="border-t border-gray-300 my-4 w-full" />
          <p className="text-white text-xs">
            Copyright © Vaihtolomat.fi All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
