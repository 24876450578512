import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer.js";
import banner3 from "../../assets/banner-x3-lomahuuto.png";
import banner4 from "../../assets/banner-x3-sunholidays.png";
import cabin from "../../assets/cabin.jpg"; // Background image

const Layout = ({ children }) => {
  return (
    <div className="relative min-h-screen">
      {/* Background container to ensure background covers everything */}
      <div
        className="absolute inset-0 bg-cover bg-center bg-fixed"
        style={{
          backgroundImage: `url(${cabin})`, // Apply background image globally
        }}
      ></div>

      {/* The main content should be on top of the background */}
      <div className="relative z-10 flex flex-col min-h-screen">
        <NavBar />

        {/* Content container with padding to accommodate the navbar */}
        <div className="pt-24 md:pt-32 lg:pt-36 flex-grow">
          {/* Ads section */}
          <div className="flex flex-row w-full bg-transparent p-4 text-center">
            <a
              href="https://link-to-your-banner3.com"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-auto h-64 rounded-lg hover:cursor-pointer hover:outline outline-black outline-2 flex items-center"
            >
              <img src={banner3} alt="banner3" className="h-64 rounded-lg" />
            </a>
            <a
              href="https://link-to-your-banner4.com"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-auto h-64 rounded-lg hover:cursor-pointer hover:outline outline-black outline-2 flex items-center"
            >
              <img src={banner4} alt="banner4" className="h-64 rounded-lg" />
            </a>
          </div>

          {/* Children content */}
          <div className="flex-grow">{children}</div>
        </div>

        <div className="flex flex-row w-full bg-transparent p-4 text-center">
          <a
            href="https://link-to-your-banner4.com"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-auto h-64 rounded-lg hover:cursor-pointer hover:outline outline-black outline-2 flex items-center"
          >
            <img src={banner4} alt="banner4" className="h-64 rounded-lg" />
          </a>
          <a
            href="https://link-to-your-banner3.com"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-auto h-64 rounded-lg hover:cursor-pointer hover:outline outline-black outline-2 flex items-center"
          >
            <img src={banner3} alt="banner3" className="h-64 rounded-lg" />
          </a>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Layout;
