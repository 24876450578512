import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Prices from "./pages/Prices";
import Profile from "./pages/Profile";
import CalculatorPage from "./pages/CalculatorPage";
import Terms from "./pages/Terms";
import Layout from "./components/layout";
import Users from "./pages/Users";
import Images from "./pages/Images";
import Links from "./pages/Links";
import { AuthProvider, useAuth } from "./context/authContext";

const AppRoutes = () => {
  const { user } = useAuth();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/kirjaudu" element={<Login />} />
      <Route path="/rekisteroidy" element={<Register />} />
      <Route path="/hinnat" element={<Prices />} />
      <Route path="/profiili" element={<Profile />} />
      <Route path="/laskin" element={<CalculatorPage />} />
      <Route path="/kayttoehdot" element={<Terms />} />
      {user?.isAdmin && <Route path="/kayttajat" element={<Users />} />}
      {user?.isAdmin && <Route path="/kuvat" element={<Images />} />}
      {user?.isAdmin && <Route path="/linkit" element={<Links />} />}
    </Routes>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <AppRoutes />
        </Layout>
      </Router>
    </AuthProvider>
  );
};

export default App;
