import { useState, useEffect } from "react";
import axios from "axios";
import Card from "../components/Card";
import ConfirmationModal from "../components/ConfirmationModal";
import { useAuth } from "../context/authContext";
import TimeshareInfoInput from "../components/TimeshareInfoInput";
import { calculateAdjustedCredits } from "../utils/creditUtils";
import Modal from "../components/Modal";

const Profile = () => {
  const [timeShares, setTimeShares] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [selectedTimeshare, setSelectedTimeshare] = useState(null);
  const [adjustedCredits, setAdjustedCredits] = useState(0); // State to store adjusted credits
  const [bookedWeeks, setBookedWeeks] = useState([]);
  const [showTimeshareInput, setShowTimeshareInput] = useState(false);
  const [rciBlocked, setRciBlocked] = useState(false);
  const [showRCIModal, setShowRCIModal] = useState(false);
  const [timeshareInputs, setTimeshareInputs] = useState([
    {
      id: 1,
      place: "",
      property: "",
      startDate: "",
      endDate: "",
      rooms: "",
      sleeping: "",
      room: "",
      rci: "",
    },
  ]);

  const { user, updateUserCredits } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (user && user.id) {
      fetchTimeshares();
    }
  }, [user, apiUrl]);

  const fetchTimeshares = () => {
    if (user && user.id) {
      axios
        .get(`${apiUrl}/timeshares/${user.id}`, {
          withCredentials: true,
        })
        .then((response) => {
          console.log("Raw API Response:", response.data); // Log the raw API response

          const filteredTimeshares = response.data.filter(
            (timeshare) => timeshare.listed !== 1
          );

          filteredTimeshares.forEach((ts) => {
            console.log(
              `Fetched timeshare credits for ${ts.property_name}:`,
              ts.credits
            );
          });

          setTimeShares(filteredTimeshares);
        })
        .catch((error) => {
          console.error("Error fetching timeshares:", error);
        });
    } else {
      console.error("User is not defined or missing id");
    }
  };

  useEffect(() => {
    if (user && user.id) {
      axios
        .get(`${apiUrl}/timeshares/booked/${user.id}`, {
          withCredentials: true, // Ensure credentials are included
        })
        .then((response) => {
          setBookedWeeks(response.data);
        })
        .catch((error) =>
          console.error("Error fetching booked timeshares:", error)
        );
    }
  }, [user, apiUrl]);

  const handleDepositClick = (timeshare, calculatedCredits) => {
    console.log("Original timeshare data:", timeshare);
    console.log("Calculated Credits:", calculatedCredits);

    const currentDate = new Date();
    const startDateMinus7Days = new Date(
      new Date(timeshare.start_date).getTime() - 7 * 24 * 60 * 60 * 1000
    );

    if (currentDate >= startDateMinus7Days) {
      window.alert(
        "Et voi tallettaa viikkoa, jonka alkamiseen on alle 7 päivää."
      );
      return;
    }

    setSelectedUnitId(timeshare.unit_id);
    setSelectedTimeshare(timeshare);
    setAdjustedCredits(calculatedCredits); // Use the pre-calculated credits
    setShowModal(true);
  };

  const handleDepositConfirm = () => {
    if (!selectedTimeshare) return;

    setShowModal(false);

    axios
      .put(
        `${apiUrl}/timeshares/deposit/${selectedTimeshare.unit_id}`,
        {
          credits: adjustedCredits,
          user_id: user.id,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        updateUserCredits();
        fetchTimeshares();
      })
      .catch((error) => console.error("Error depositing week:", error));
  };

  const formatDateToMySQL = (date) => {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const postTimeshare = () => {
    if (rciBlocked) {
      setShowRCIModal(true);
      return;
    }

    const newTimeshare = timeshareInputs[timeshareInputs.length - 1];

    const startDate = new Date(newTimeshare.startDate);
    const endDate = new Date(newTimeshare.endDate);

    if (isNaN(startDate) || isNaN(endDate)) {
      console.error("Invalid date values provided");
      return;
    }

    const formattedStartDate = formatDateToMySQL(startDate);
    const formattedEndDate = formatDateToMySQL(endDate);

    const initialCredits =
      newTimeshare.credits?.Krediitit || newTimeshare.credits || 0;

    if (!initialCredits) {
      console.error("Invalid initial credits provided");
      return;
    }

    // Only calculate adjusted credits here if necessary for posting the timeshare
    const adjustedCredits = calculateAdjustedCredits(
      formattedStartDate,
      initialCredits
    );

    console.log("Formatted Timeshare Data:", {
      user_id: user.id,
      property_name: newTimeshare.property || "Unknown Property",
      location: newTimeshare.place || "Unknown Location",
      unit_number: newTimeshare.room || "N/A",
      size: newTimeshare.rooms || "N/A",
      sleeping_places: newTimeshare.sleeping || "N/A",
      rci_points: newTimeshare.rci || "N/A",
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      credits: adjustedCredits,
    });

    const formattedTimeshare = {
      user_id: user.id,
      property_name: newTimeshare.property || "Unknown Property",
      location: newTimeshare.place || "Unknown Location",
      unit_number: newTimeshare.room || "N/A",
      size: newTimeshare.rooms || "N/A",
      sleeping_places: newTimeshare.sleeping || "N/A",
      rci_points: newTimeshare.rci || "N/A",
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      credits: initialCredits, // Use initial credits to avoid double calculation
    };

    axios
      .post(`${apiUrl}/timeshares/`, formattedTimeshare, {
        withCredentials: true,
      })
      .then((response) => {
        setTimeShares((prevTimeshares) => [
          ...prevTimeshares,
          {
            ...formattedTimeshare,
            credits: initialCredits, // Display initial credits
          },
        ]);
        setShowTimeshareInput(false);
      })
      .catch((error) => console.error("Error adding timeshare:", error));
  };

  const updateTimeshare = (index, updatedTimeshare) => {
    setTimeshareInputs((prevInputs) =>
      prevInputs.map((input, i) => (i === index ? updatedTimeshare : input))
    );
  };

  const handleTimeshareRemove = (id) => {
    setTimeshareInputs((prevInputs) =>
      prevInputs.filter((input) => input.id !== id)
    );
  };

  return (
    <div className="min-h-screen bg-transparent flex flex-col items-center p-4">
      <div className="bg-transparent py-4 px-64">
        {user ? (
          <>
            <div className="max-w-screen">
              <h1 className="text-3xl font-bold mb-4 text-center">
                Varaamasi lomat
              </h1>
              {bookedWeeks.length === 0 ? (
                <p>No booked timeshares available.</p>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {bookedWeeks.map((timeshare, index) => (
                    <Card
                      key={`${timeshare.unit_id}-${index}`}
                      timeshare={timeshare}
                      buttonText={"Varattu"}
                      disabled={true}
                    />
                  ))}
                </div>
              )}
            </div>

            <div className="max-w-screen mt-8">
              <h1 className="text-3xl font-bold mb-4 text-center">
                Talletettavissa olevat viikkosi
              </h1>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 flex justify-center">
                {timeShares.length === 0 ? (
                  <p className="col-span-full text-center">Ei viikkoja</p>
                ) : (
                  timeShares.map((timeShare, index) => {
                    const calculatedCredits = calculateAdjustedCredits(
                      timeShare.start_date,
                      timeShare.credits
                    );

                    return (
                      <Card
                        key={`${timeShare.unit_id}-${index}`}
                        timeshare={{
                          ...timeShare,
                          credits: calculatedCredits,
                        }}
                        buttonText={"Talleta viikko"}
                        onClick={() =>
                          handleDepositClick(timeShare, calculatedCredits)
                        }
                        showTimer={true}
                      />
                    );
                  })
                )}
              </div>
              {showModal && selectedTimeshare && (
                <ConfirmationModal
                  prompt={`Haluatko varmasti tallettaa tämän viikon ${adjustedCredits} lomakrediittiä vastaan?`}
                  onConfirm={handleDepositConfirm}
                  onCancel={() => setShowModal(false)}
                  checkBox={true}
                />
              )}
            </div>

            <div className="w-full max-w-screen mt-8">
              <h1 className="text-3xl font-bold mb-4 text-center">
                Lisää uusi viikko
              </h1>
              <div className="space-y-4 max-w-lg mx-auto">
                {timeshareInputs.map((input, index) => (
                  <div
                    key={input.id}
                    className="relative bg-white rounded-lg shadow-lg p-4"
                  >
                    <TimeshareInfoInput
                      label={input.label}
                      index={index}
                      timeshare={input}
                      updateTimeshare={updateTimeshare}
                      setRciBlocked={setRciBlocked}
                    />

                    <Modal
                      isVisible={showRCIModal}
                      onClose={() => setShowRCIModal(false)}
                      children={
                        "Et voi tallettaa osaketta, joka on liitetty RCI-pisteisiin. Suorita RCI-pistepurku ensin."
                      }
                    />
                  </div>
                ))}
                <div className="flex justify-center">
                  <button
                    className="bg-teal-600 hover:bg-teal-700 w-full sm:w-auto text-white py-2 px-4 rounded-xl"
                    onClick={postTimeshare}
                  >
                    Lisää uusi omistamasi osake
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <p>
            Sinun täytyy kirjautua sisään, niin pääset tarkastelemaan
            profiiliasi.
          </p>
        )}
      </div>
    </div>
  );
};

export default Profile;
