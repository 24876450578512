import { useState } from "react";
import axios from "axios";

const Links = () => {
  const [location, setLocation] = useState("");
  const [homepageUrl, setHomepageUrl] = useState("");
  const [message, setMessage] = useState("");

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiUrl}/timeshares/add-link`, {
        location: location,
        homepage_url: homepageUrl,
      });

      setMessage(response.data.message);
    } catch (error) {
      setMessage("Error adding link: " + error.response.data.message);
    }
  };

  return (
    <div className="min-h-screen bg-transparent flex flex-col items-center p-4">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col justify-center bg-white p-8 rounded-lg shadow-md"
      >
        <div>
          <label>Kohde</label>
          <select
            className="block w-full 3xl:w-full rounded-lg border-0 p-2 m-2 py-1.5 text-black shadow-sm ring-1 ring-inset ring-secondary ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          >
            <option value="">Valitse Kohde</option>
            {locationOptions.map((location) => (
              <option key={location} value={location}>
                {location.charAt(0).toUpperCase() + location.slice(1)}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Linkki</label>
          <input
            className="block w-full 3xl:w-full rounded-lg border-0 p-2 m-2 py-1.5 text-black shadow-sm ring-1 ring-inset ring-secondary ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            type="text"
            value={homepageUrl}
            onChange={(e) => setHomepageUrl(e.target.value)}
            required
          />
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-teal-600 hover:bg-teal-700 w-full sm:w-auto text-white py-2 px-4 m-2 rounded-xl"
          >
            Lisää linkki
          </button>
        </div>
        {message && <p>{message}</p>}
      </form>
    </div>
  );
};

export default Links;

const locationOptions = [
  "airisto",
  "ellivuori",
  "kivijärvi",
  "himos",
  "hoilola",
  "ikaalinen",
  "imatra",
  "kalajoki",
  "vuokatti",
  "koli",
  "kihniö",
  "kuortane",
  "kuusamo",
  "laukaa",
  "levi",
  "naantali",
  "punkaharju",
  "pyhä",
  "rönnäs",
  "ruka",
  "saimaa",
  "salla",
  "saariselkä",
  "tahko",
  "tampere",
  "turku",
  "vierumäki",
  "ylläs",
  "ähtäri",
  "ulkomaat",
];
