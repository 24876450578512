import React from "react";
import { useState, useEffect } from "react";
import { format, addDays } from "date-fns";
import DatePicker from "./DatePicker";
import axios from "axios";
import "./styles.css";

const TimeshareInfoInput = ({
  label,
  index,
  timeshare,
  updateTimeshare,
  setRciBlocked,
}) => {
  const [place, setPlace] = useState(timeshare.place || "");
  const [property, setProperty] = useState(timeshare.property || "");
  const [startDate, setStartDate] = useState(timeshare.startDate || "");
  const [endDate, setEndDate] = useState(timeshare.endDate || "");
  const [rooms, setRooms] = useState(timeshare.rooms || "studio");
  const [sleeping, setSleeping] = useState(timeshare.sleeping || "2");
  const [room, setRoom] = useState(timeshare.room || "");
  const [rci, setRci] = useState(timeshare.rci || "true");
  const [credits, setCredits] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    updateTimeshare(index, {
      ...timeshare,
      place,
      property,
      startDate,
      endDate,
      rooms,
      sleeping,
      room,
      rci,
      credits,
    });
  }, [
    place,
    property,
    startDate,
    endDate,
    rooms,
    sleeping,
    room,
    rci,
    credits,
  ]);

  useEffect(() => {
    if (property && startDate && sleeping) {
      calculateCredits();
    }
  }, [property, startDate, sleeping]);

  useEffect(() => {
    // If RCI is "true", notify the parent component to block submission
    if (rci === "true") {
      setRciBlocked(true);
    } else {
      setRciBlocked(false);
    }
  }, [rci]);

  const calculateCredits = () => {
    axios
      .get(`${apiUrl}/timeshares/`, { withCredentials: true })
      .then((response) => {
        const data = response.data;
        const flattenedData = Object.values(data).flat();
        const result = flattenedData.find(
          (item) =>
            item.Lomakohde === property &&
            item.Viikko === getWeekNumber(new Date(startDate)) &&
            item.Nukkumapaikat === parseInt(sleeping)
        );
        const calculatedCredits = result;
        console.log("Calculated credits:", calculatedCredits);
        console.log("viikko", getWeekNumber(new Date(startDate)));

        setCredits(calculatedCredits);
        updateTimeshare(index, { ...timeshare, credits: calculatedCredits });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setCredits(0);
        updateTimeshare(index, { ...timeshare, credits: 0 });
      });
  };

  const handlePlaceChange = (e) => {
    const selectedPlace = e.target.value;
    setPlace(selectedPlace);
    setProperty("");
    const properties = searchOptions[selectedPlace];
    const newProperty =
      properties && properties.length === 1 ? properties[0] : "";
    setProperty(newProperty);
    updateTimeshare(index, {
      ...timeshare,
      place: selectedPlace,
      property: newProperty,
    });
  };

  const handlePropertyChange = (e) => {
    const selectedProperty = e.target.value;
    setProperty(selectedProperty);
    updateTimeshare(index, { ...timeshare, property: selectedProperty });
  };

  const handleRciChange = (e) => {
    const selectedRci = e.target.value;
    setRci(selectedRci);
    updateTimeshare(index, { ...timeshare, rci: selectedRci });
  };

  const handleDateChange = (date) => {
    const startDate = date;
    const endDate = addDays(date, 7);
    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(endDate, "yyyy-MM-dd");
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    updateTimeshare(index, {
      ...timeshare,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="">
      <h3 className="text-center text-2xl font-roboto mb-4">{label}</h3>
      {/* Property name input */}
      <label
        htmlFor={`place-${index}`}
        className="block text-sm font-medium leading-6 text-black"
      >
        <div className="flex flex-row ml-8">
          <p className="font-roboto text-lg lg:text-xl 3xl:ml-20 text-paragraph">
            Sijainti
          </p>
        </div>
      </label>

      <div className="flex justify-center mt-2">
        <select
          id={`place-${index}`}
          name={`place-${index}`}
          type="text"
          autoComplete="property"
          required
          /*  style={
            errors.property
              ? { border: "1px solid #e53e3e" }
              : { border: "1px solid #ffa400" }
          } */
          className="block w-3/4 3xl:w-2/3 rounded-xl border-0 p-2 py-1.5 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 placeholder:text-gray-400 focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none sm:text-sm sm:leading-6 custom-dropdown"
          placeholder="Sijainti"
          onChange={handlePlaceChange}
          value={place}
        >
          <option value="">Valitse sijainti</option>
          {Object.keys(searchOptions).map((place) => (
            <option key={place} value={place}>
              {capitalizeFirstLetter(place)}
            </option>
          ))}
        </select>
      </div>

      <label
        htmlFor={`property-${index}`}
        className="block text-sm font-medium leading-6 text-black mt-4"
      >
        <div className="flex flex-row ml-8">
          <p className="font-roboto text-lg lg:text-xl 3xl:ml-20 text-paragraph">
            Yhtiö
          </p>
        </div>
      </label>

      <div className="flex justify-center mt-2">
        {place !== "muu" ? (
          <select
            id={`property-${index}`}
            name={`property-${index}`}
            type="text"
            autoComplete="property"
            required
            /*  style={
            errors.property
            ? { border: "1px solid #e53e3e" }
            : { border: "1px solid #ffa400" }
          } */
            className="block w-3/4 3xl:w-2/3 rounded-xl border-0 p-2 py-1.5 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 placeholder:text-gray-400 focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none sm:text-sm sm:leading-6 custom-dropdown"
            placeholder="Kiinteistö Oy:n nimi"
            onChange={handlePropertyChange}
            value={property}
          >
            <option value="">Valitse yhtiö</option>
            {place &&
              searchOptions[place].map((property) => (
                <option key={property} value={property}>
                  {property}
                </option>
              ))}
          </select>
        ) : (
          <input
            id={`property-${index}`}
            name={`property-${index}`}
            type="text"
            autoComplete="property"
            required
            className="block w-3/4 3xl:w-2/3 h-8 rounded-xl border-0 p-2 py-1.5 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 placeholder:text-gray-400 focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none sm:text-sm sm:leading-6 custom-dropdown"
            placeholder="Kiinteistö Oy:n nimi"
            value={property}
            onChange={(e) => setProperty(e.target.value)}
          />
        )}
      </div>

      <label
        htmlFor="week"
        className="block text-sm font-medium leading-6 text-black mt-4"
      >
        <div className="flex flex-row ml-8">
          {/* <Calendar className="h-5 w-5 mr-2" /> */}
          <p className="font-roboto text-lg lg:text-xl 3xl:ml-20 text-paragraph">
            Viikko
          </p>
        </div>
      </label>

      <div className="flex flex-col justify-center items-center">
        <label className="block text-sm font-medium leading-6 text-black">
          Valitse osakkeesi oikeuttavan lomaviikon seuraava alkamispäivä
        </label>
        <DatePicker onDateChange={handleDateChange} />
      </div>

      {/* Room amount input */}
      <label
        htmlFor="rooms"
        className="block text-sm font-medium leading-6 text-black mt-4"
      >
        <div className="flex flex-row ml-8">
          {/* <Calendar className="h-5 w-5 mr-2" /> */}
          <p className="font-roboto text-lg lg:text-xl 3xl:ml-20 text-paragraph">
            Huoneiston koko
          </p>
        </div>
      </label>

      {/* Room amount input */}
      <div className="flex justify-center mt-2">
        <select
          id="rooms"
          name="rooms"
          required
          className="block w-3/4 3xl:w-2/3 rounded-xl border-0 p-2 py-1.5 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 placeholder:text-gray-400 focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none sm:text-sm sm:leading-6 custom-dropdown"
          value={rooms}
          onChange={(e) => setRooms(e.target.value)}
        >
          <option value="studio">Studio</option>
          <option value="1mh">1mh</option>
          <option value="2mh">2mh</option>
          <option value="3mh">3mh</option>
          <option value="bigger">Suurempi</option>
        </select>
      </div>

      {/* Sleeping places input */}
      <label
        htmlFor="rooms"
        className="block text-sm font-medium leading-6 text-black mt-4"
      >
        <div className="flex flex-row ml-8">
          {/* <Calendar className="h-5 w-5 mr-2" /> */}
          <p className="font-roboto text-lg lg:text-xl 3xl:ml-20 text-paragraph">
            Nukkumapaikat
          </p>
        </div>
      </label>

      <div className="flex justify-center mt-2">
        <select
          id="sleeping"
          name="sleeping"
          required
          className="block w-3/4 3xl:w-2/3 h-8 rounded-xl border-0 p-2 py-1.5 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 placeholder:text-gray-400 focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none sm:text-sm sm:leading-6 custom-dropdown"
          value={sleeping}
          onChange={(e) => setSleeping(e.target.value)}
        >
          <option value="2">2</option>
          <option value="4">4</option>
          <option value="6">6</option>
          <option value="8">8</option>
          <option value="more">Enemmän</option>
        </select>
      </div>

      {/* Room number input */}
      <label
        htmlFor="room number"
        className="block text-sm font-medium leading-6 text-black mt-4"
      >
        <div className="flex flex-row ml-8">
          {/* <Calendar className="h-5 w-5 mr-2" /> */}
          <p className="font-roboto text-lg lg:text-xl 3xl:ml-20 text-paragraph">
            Huoneiston tunnus
          </p>
        </div>
      </label>

      <div className="flex justify-center mt-2">
        <input
          id="room"
          name="room"
          type="text"
          autoComplete="room"
          required
          /*  style={
              errors.room
                ? { border: "1px solid #e53e3e" }
                : { border: "1px solid #ffa400" }
            } */
          className="block w-3/4 3xl:w-2/3 h-8 rounded-xl border-0 p-2 py-1.5 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 placeholder:text-gray-400 focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none sm:text-sm sm:leading-6"
          placeholder=""
          value={room}
          onChange={(e) => setRoom(e.target.value)}
        />
      </div>

      <label
        htmlFor="rci"
        className="block text-sm font-medium leading-6 text-black mt-4"
      >
        <div className="flex flex-row ml-8">
          {/* <Calendar className="h-5 w-5 mr-2" /> */}
          <p className="font-roboto text-lg lg:text-xl 3xl:ml-20 text-paragraph">
            Onko liitetty RCI-pisteisiin?
          </p>
        </div>
      </label>

      {/* RCI points input */}
      <div className="flex justify-center mt-2">
        <select
          id="rci"
          name="rci"
          required
          className="block w-3/4 3xl:w-2/3 h-8 rounded-xl border-0 p-2 py-1.5 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 placeholder:text-gray-400 focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none sm:text-sm sm:leading-6 custom-dropdown"
          value={rci}
          onChange={handleRciChange}
        >
          <option value="true">Kyllä</option>
          <option value="false">Ei</option>
        </select>
      </div>
    </div>
  );
};

export default TimeshareInfoInput;

const searchOptions = {
  airisto: ["Airiston Fregatti", "Airiston Kuunari", "Airiston Kutteri"],
  ellivuori: ["Ellin Loisto I"],
  kivijärvi: ["Hannunkiven Lomakylä"],
  himos: [
    "Himoksen Aurinkopaikka",
    "Himoksen Tähti I",
    "Villas Himos I",
    "Villas Himos II",
    "Villas Himos III",
  ],
  hoilola: ["Onnenvirta II", "Onnenvirta III"],
  ikaalinen: ["Ikaalisten Mäntypiha"],
  imatra: ["Imatra Spa Viikko Oy"],
  kalajoki: ["Särkkäin lomaparatiisi II", "Rantabeach", "Kalajoen Keidas"],
  vuokatti: [
    "Katinkullan Golfharju",
    "Katinkullan Hiekkaniemi",
    "Katinkullan Kiinteistöt",
    "Katinkultaniemi",
    "Katinkultaranta",
    "Katinkullan Rantahovi",
    "Katinkulta Residence",
    "Katinkullan Golfpuisto",
    "Katinkulta Spa Lodge 1",
    "Katinkulta Spa Lodge 2",
    "Villas Katinkulta Spa 1",
    "Villas Katinkulta Spa 1 Lodge",
    "Villas Katinkulta Spa 2",
    "Villas Katinkulta Golf Park",
    "Vuokatti Country Club",
    "Vuokatin Kulta-Katti",
    "Vuokatin Lepokatti",
  ],
  koli: ["Kolin Kukkula"],
  kihniö: [
    "Pyhäniemi II",
    "Pyhäniemi III",
    "Pyhäniemi IV",
    "Pyhäniemi V",
    "Pyhäniemi VI",
    "Pyhäniemi VII",
    "Pyhäniemi VIII",
  ],
  kuortane: ["Kuortaneen Liikuntahotelli"],
  kuusamo: [
    "Kuusamon Lampitropiikki",
    "Kuusamon Tähti 1",
    "Kuusamon Lomaparatiisi",
    "Kuusamon Rantatropiikki",
    "Kuusamon Rantatropiikki 2",
    "Petäjälampi 6 Lodge",
    "Petäjälammenranta 7 Lodge",
  ],
  laukaa: ["Pitkäniemi III"],
  levi: [
    "Levi-Rakkavaara Club 1",
    "Rakkavaara Club Int. Ltd",
    "Abgott",
    "Aruudenia",
  ],
  naantali: ["Naantalin kylpyläranta", "Sunborn Vacation Club 1"],
  punkaharju: ["Hiekkaharju 1", "Hiekkaharju 2"],
  pyhä: ["Onninpyhä", "Pyhänhovi", "Pyhä HolySuites"],
  rönnäs: ["Rönnäs Country Club"],
  ruka: ["Rukan Lomakylä I", "RukaVillage Suites 1"],
  saimaa: [
    "Anttilankaari 6",
    "Anttilankaari 8",
    "Anttilankaari 10",
    "Vipelentie 35",
    "Saimaanranta",
    "Saimaanranta 2",
    "Saimaanranta 3",
    "Saimaanrantapuisto",
    "Saimaan Keskuspuisto Lodge",
    "Saimaa Pearl Lodge 1",
    "Saimaa Spa Lodge 1",
    "Saimaa Spa Lodge 2",
    "Saimaa Spa Lodge 3",
  ],
  salla: [
    "Sallas huoneistot",
    "Sallan tähti",
    "Sallatunturin Kelorinne",
    "Sallan Eraustähti",
    "Villas Sallatunturi 1",
    "Villas Sallatunturi 2",
  ],
  saariselkä: [
    "Kermikkä",
    "Siulaselkä",
    "Kelotirro",
    "Riekonraito",
    "Laavutieva",
    "Tirrolampi",
    "Nilihonka",
    "Saariselkä Spa Lodge 2",
    "Kelotähti 1 Lodge",
    "Ruskarinne",
  ],
  tahko: [
    "Tahkotime",
    "Leppätahko",
    "Nilsiän Rentotahko",
    "Spa Suites Black",
    "Spa Suites White",
  ],
  tampere: [
    "Näsijärven Kimallus",
    "Lapiinniemi I",
    "Lapinniemi II",
    "Lapinniemi III",
    "Lapinniemi IV",
    "Lapinniemi V",
    "Lapinniemi VI",
    "Lapinniemi VII",
    "Lapinniemi VIII",
    "Lapinniemi IX",
    "Lapinniemi X",
    "Lapinniemi XI",
    "Lapinniemi XII",
    "Lapinniemi XIII",
    "Lapinniemi XIV",
    "Lapinniemi XV",
    "Lapinniemi XVI",
    "Lapinniemi XVII",
    "Lapinniemi XVIII",
    "Lapinniemi XIX",
    "Lapinniemi XX",
  ],
  turku: ["Caribia Spa Lodge 1", "HC Villas Turun Caribia"],
  vierumäki: [
    "HC Villas Vierumäki 1",
    "HC Villas Vierumäki 2",
    "Vierumäki Golf Resort",
  ],
  ylläs: [
    "Kolarin Siepakka",
    "Kesänki",
    "Kuer",
    "Muiro",
    "Musko",
    "Mokko",
    "Ylläksen Rautamajat",
    "Ylläs Saaga",
    "Ylläs viikko 1",
    "Ylläksen lomaviikot",
  ],
  ähtäri: ["Ähtärin Lomakylä", "Moksunhonka 1"],
  ulkomaat: [
    "Jardin Amadores",
    "Playa Amadores",
    "Puerto Calma",
    "Sol Amadores",
    "Vista Amadores",
    "Åre",
  ],
};
