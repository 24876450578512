import React, { useState, useEffect } from "react";

const Timer = ({ startDate }) => {
  const calculateTimeLeft = () => {
    const targetDate = new Date(startDate);
    targetDate.setDate(targetDate.getDate() - 7); // Subtract 7 days from the start date
    const now = new Date();
    const difference = +targetDate - +now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        total: difference,
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        total: difference,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const renderTimeLeft = () => {
    if (timeLeft.total <= 0) {
      return <span>Aika loppui!</span>;
    }

    if (timeLeft.days > 0) {
      return <span>{timeLeft.days} päivää aikaa tallettaa viikkosi</span>;
    }

    if (timeLeft.hours > 0 || timeLeft.minutes > 0) {
      return (
        <span>
          {timeLeft.hours} tuntia ja {timeLeft.minutes} minuuttia aikaa
          tallettaa viikkosi
        </span>
      );
    }

    return null;
  };

  return (
    <div className="text-xl text-gray-500 font-bold">{renderTimeLeft()}</div>
  );
};

export default Timer;
