import React, { useEffect } from "react";
import remove from "../assets/cross-circle.svg";

const Modal = ({ children, isVisible, onClose }) => {
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-xl shadow-lg p-6 w-1/3 max-h-[80vh] h-auto relative overflow-y-auto flex flex-col">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <img src={remove} alt="close" className="w-6" />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
