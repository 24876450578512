import React, { useState } from "react";
import axios from "axios";

const Images = () => {
  const [location, setLocation] = useState(""); // For selected location
  const [propertyName, setPropertyName] = useState(""); // For selected property
  const [images, setImages] = useState([]); // For multiple images
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleImageUpload = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("property_name", propertyName); // Double-check this is being set
    formData.append("image_description", description);

    // Append all the selected images to the formData
    for (let i = 0; i < images.length; i++) {
      formData.append("images", images[i]); // 'images' is the key for multiple images
    }

    // Log the FormData keys and values to ensure they are populated correctly
    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const response = await axios.post(
        `${apiUrl}/timeshares/upload`, // Adjust endpoint as needed
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            withCredentials: true,
          },
        }
      );
      setMessage(response.data.message);
    } catch (error) {
      console.error("Error uploading images:", error);
      setMessage("Error uploading images");
    }
  };

  return (
    <div className="min-h-screen bg-transparent flex flex-col items-center p-4">
      <h1 className="text-3xl font-bold mb-4 text-center">Lataa kuvia</h1>
      <form
        className="bg-white p-4 rounded-lg shadow-md"
        onSubmit={handleImageUpload}
        encType="multipart/form-data"
      >
        {/* Location Dropdown */}
        <div>
          <label htmlFor="location">Valitse Paikka</label>
          <select
            className="w-full p-2 m-2 border border-gray-300 rounded-lg"
            id="location"
            value={location}
            onChange={(e) => {
              setLocation(e.target.value);
              setPropertyName(""); // Reset property name when changing location
            }}
            required
          >
            <option value="">Valitse paikka</option>
            {Object.keys(searchOptions).map((loc) => (
              <option key={loc} value={loc}>
                {loc.charAt(0).toUpperCase() + loc.slice(1)}
              </option>
            ))}
          </select>
        </div>

        {/* Property Name Dropdown */}
        <div>
          <label htmlFor="property_name">Valitse kiinteistöyhtiö</label>
          <select
            className="w-full p-2 m-2 border border-gray-300 rounded-lg"
            id="property_name"
            value={propertyName}
            onChange={(e) => setPropertyName(e.target.value)}
            required
            disabled={!location} // Disable the dropdown until location is selected
          >
            <option value="">Valitse kiinteistöyhtiö</option>
            {location &&
              searchOptions[location].map((property) => (
                <option key={property} value={property}>
                  {property}
                </option>
              ))}
          </select>
        </div>

        {/* Multiple Image Upload */}
        <div className="my-4">
          <label htmlFor="images" className="">
            Valitse kuva(t)
          </label>
          <input
            className="block w-full p-2 m-2 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-lg cursor-pointer focus:outline-none focus:border-teal-600"
            type="file"
            id="images"
            multiple
            onChange={(e) => setImages([...e.target.files])} // Set multiple images
            required
          />
        </div>

        <div>
          <label htmlFor="description">Kuvaus</label>
          <input
            className="block w-full 3xl:w-full rounded-lg border-0 p-2 m-2 py-1.5 text-black shadow-sm ring-1 ring-inset ring-secondary ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            type="text"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-teal-600 hover:bg-teal-700 w-full sm:w-auto text-white py-2 px-4 rounded-xl"
          >
            Lataa kuva(t)
          </button>
        </div>
      </form>

      {message && <p>{message}</p>}
    </div>
  );
};

export default Images;

const searchOptions = {
  airisto: ["Airiston Fregatti", "Airiston Kuunari", "Airiston Kutteri"],
  ellivuori: ["Ellin Loisto I"],
  kivijärvi: ["Hannunkiven Lomakylä"],
  himos: [
    "Himoksen Aurinkopaikka",
    "Himoksen Tähti I",
    "Villas Himos I",
    "Villas Himos II",
    "Villas Himos III",
  ],
  hoilola: ["Onnenvirta II", "Onnenvirta III"],
  ikaalinen: ["Ikaalisten Mäntypiha"],
  imatra: ["Imatra Spa Viikko Oy"],
  kalajoki: ["Särkkäin lomaparatiisi II", "Rantabeach", "Kalajoen Keidas"],
  vuokatti: [
    "Katinkullan Golfharju",
    "Katinkullan Hiekkaniemi",
    "Katinkullan Kiinteistöt",
    "Katinkultaniemi",
    "Katinkultaranta",
    "Katinkullan Rantahovi",
    "Katinkulta Residence",
    "Katinkullan Golfpuisto",
    "Katinkulta Spa Lodge 1",
    "Katinkulta Spa Lodge 2",
    "Villas Katinkulta Spa 1",
    "Villas Katinkulta Spa 1 Lodge",
    "Villas Katinkulta Spa 2",
    "Villas Katinkulta Golf Park",
    "Vuokatti Country Club",
    "Vuokatin Kulta-Katti",
    "Vuokatin Lepokatti",
  ],
  koli: ["Kolin Kukkula"],
  kihniö: [
    "Pyhäniemi II",
    "Pyhäniemi III",
    "Pyhäniemi IV",
    "Pyhäniemi V",
    "Pyhäniemi VI",
    "Pyhäniemi VII",
    "Pyhäniemi VIII",
  ],
  kuortane: ["Kuortaneen Liikuntahotelli"],
  kuusamo: [
    "Kuusamon Lampitropiikki",
    "Kuusamon Tähti 1",
    "Kuusamon Lomaparatiisi",
    "Kuusamon Rantatropiikki",
    "Kuusamon Rantatropiikki 2",
    "Petäjälampi 6 Lodge",
    "Petäjälammenranta 7 Lodge",
  ],
  laukaa: ["Pitkäniemi III"],
  levi: [
    "Levi-Rakkavaara Club 1",
    "Rakkavaara Club Int. Ltd",
    "Abgott",
    "Aruudenia",
  ],
  naantali: ["Naantalin kylpyläranta", "Sunborn Vacation Club 1"],
  punkaharju: ["Hiekkaharju 1", "Hiekkaharju 2"],
  pyhä: ["Onninpyhä", "Pyhänhovi", "Pyhä HolySuites"],
  rönnäs: ["Rönnäs Country Club"],
  ruka: ["Rukan Lomakylä I", "RukaVillage Suites 1"],
  saimaa: [
    "Anttilankaari 6",
    "Anttilankaari 8",
    "Anttilankaari 10",
    "Vipelentie 35",
    "Saimaanranta",
    "Saimaanranta 2",
    "Saimaanranta 3",
    "Saimaanrantapuisto",
    "Saimaan Keskuspuisto Lodge",
    "Saimaa Pearl Lodge 1",
    "Saimaa Spa Lodge 1",
    "Saimaa Spa Lodge 2",
    "Saimaa Spa Lodge 3",
  ],
  salla: [
    "Sallas huoneistot",
    "Sallan tähti",
    "Sallatunturin Kelorinne",
    "Sallan Eraustähti",
    "Villas Sallatunturi 1",
    "Villas Sallatunturi 2",
  ],
  saariselkä: [
    "Kermikkä",
    "Siulaselkä",
    "Kelotirro",
    "Riekonraito",
    "Laavutieva",
    "Tirrolampi",
    "Nilihonka",
    "Saariselkä Spa Lodge 2",
    "Kelotähti 1 Lodge",
    "Ruskarinne",
  ],
  tahko: [
    "Tahkotime",
    "Leppätahko",
    "Nilsiän Rentotahko",
    "Spa Suites Black",
    "Spa Suites White",
  ],
  tampere: [
    "Näsijärven Kimallus",
    "Lapiinniemi I",
    "Lapinniemi II",
    "Lapinniemi III",
    "Lapinniemi IV",
    "Lapinniemi V",
    "Lapinniemi VI",
    "Lapinniemi VII",
    "Lapinniemi VIII",
    "Lapinniemi IX",
    "Lapinniemi X",
    "Lapinniemi XI",
    "Lapinniemi XII",
    "Lapinniemi XIII",
    "Lapinniemi XIV",
    "Lapinniemi XV",
    "Lapinniemi XVI",
    "Lapinniemi XVII",
    "Lapinniemi XVIII",
    "Lapinniemi XIX",
    "Lapinniemi XX",
  ],
  turku: ["Caribia Spa Lodge 1", "HC Villas Turun Caribia"],
  vierumäki: [
    "HC Villas Vierumäki 1",
    "HC Villas Vierumäki 2",
    "Vierumäki Golf Resort",
  ],
  ylläs: [
    "Kolarin Siepakka",
    "Kesänki",
    "Kuer",
    "Muiro",
    "Musko",
    "Mokko",
    "Ylläksen Rautamajat",
    "Ylläs Saaga",
    "Ylläs viikko 1",
    "Ylläksen lomaviikot",
  ],
  ähtäri: ["Ähtärin Lomakylä", "Moksunhonka 1"],
  ulkomaat: [
    "Jardin Amadores",
    "Playa Amadores",
    "Puerto Calma",
    "Sol Amadores",
    "Vista Amadores",
    "Åre",
  ],
};
